<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 qr-code-templates-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="reception-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "utilities",
              "qr-code-templates",
              "qr-code-templates"
            )
          }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="!editing"
            :to="{
              name: 'r_add-qr-code-template'
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('utilities', 'qr-code-templates', 'search')
            "
          />
          <div class="icon search"><svg-icon icon="lense"></svg-icon></div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfTemplates && listOfTemplates.length"
    >
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': !editing }"
        v-for="template in listOfTemplates"
        :key="template.id"
      >
        <div class="clebex-item-content-wrapper" v-if="!editing">
          <span class="follow-up-icons">
            <button
              class="follow-up-icon-item"
              :class="{
                default: template.is_default
              }"
              :disabled="template.is_default === true"
              @click.stop="changeTemplateDefault(template)"
            >
              <icon v-if="template.is_default" icon="#cx-app1-favourite-fill" />
              <icon v-else icon="#cx-app1-favourite" />
            </button>
          </span>
          <span class="label" @click.stop="editTemplate(template)">
            <span class="highlight">{{ template.name }}</span>
          </span>
          <span class="follow-up-icons" @click.stop="editTemplate(template)">
            <button class="follow-up-icon-item">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </button>
          </span>
        </div>

        <div
          class="clebex-item-content-wrapper"
          v-else-if="!template.is_default"
        >
          <div class="checkbox tiny alt block">
            <input
              type="radio"
              :id="`qr-code-template${template.id}`"
              name="user"
              :selected="
                template.id === selectedTemplate && selectedTemplate.id
              "
              :value="template.id"
              @change="selectForDelete(template)"
            />
            <label :for="`qr-code-template${template.id}`">
              <icon icon="#cx-app1-checkmark" />
              <span class="label inner-text no-left-padding">
                <span class="highlight">{{ template.name }}</span>
              </span>
            </label>
          </div>
        </div>
      </li>
    </ul>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{ displayLabelName("utilities", "qr-code-templates", "select") }}
            </button>
          </li>
          <li class="action" v-if="editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("utilities.messages.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("utilities.messages.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "Receptions",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [paginationMixin],
  data() {
    return {
      searchQuery: "",
      show: false,
      processing: false,
      editing: false,
      selectedForDelete: null
    };
  },
  created() {
    this.getTemplates();
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true
    });
  },
  computed: {
    ...mapState("qrCode", ["templates", "selectedTemplate"]),
    listOfTemplates() {
      const query = this.searchQuery;
      if (this.templates && this.templates.length) {
        if (query && query.length > 1) {
          return this.templates.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.templates;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("qrCode", [
      "getTemplates",
      "setSelectedTemplate",
      "deleteTemplate",
      "setAsDefaultTemplate"
    ]),
    selectForDelete(template) {
      this.selectedForDelete = template;
    },
    startDelete() {
      this.processing = true;
      this.deleteTemplate(this.selectedForDelete.id).then(() => {
        this.selectedForDelete = null;
        this.processing = false;
        this.editing = false;
        this.getTemplates();
      });
    },
    setEditing() {
      this.setSelectedTemplate(null);
      this.editing = !this.editing;
      this.$router.push({ name: "r_qr-code-templates" });
    },
    editTemplate(tmp) {
      this.$route.params.templateId = tmp.id;
      this.$router.push({
        name: "r_edit-qr-code-template",
        params: this.$route.params
      });
    },
    changeTemplateDefault(tmp) {
      tmp.is_default = !tmp.is_default;
      this.setAsDefaultTemplate(tmp.id).then(() => {
        this.getTemplates();
      });
    }
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true
    });
  }
};
</script>
